import React from 'react';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { FedopsLoggerStore } from '../../stores/FedopsLoggerStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { TemplatesGrid } from './TemplatesGrid/TemplatesGrid';

interface TemplatesProps {
  fedopsLoggerStore: FedopsLoggerStore;
  templatesStore: TemplatesStore;
  biLoggerStore: BILoggerStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
}

const TemplatesCmp: React.FC<TemplatesProps> = ({
  templatesStore,
  dataHook,
  biLoggerStore,
  experimentsStore,
  fedopsLoggerStore,
}) => {
  const { items, responseUuid } = templatesStore;
  const templates =
    experimentsStore.is('specs.funnel.RecommendedTemplatesSection', 'true') && templatesStore.bestMatchItemIds.length
      ? items.slice(3)
      : items;

  React.useEffect(() => {
    fedopsLoggerStore.stopActiveInteraction();
    biLoggerStore.logPageFullyRendered(biLoggerStore.getLastInteractionKey());
    biLoggerStore.logTemplatesLoaded();
  }, [fedopsLoggerStore, responseUuid, biLoggerStore]);

  return <TemplatesGrid dataHook={dataHook} items={templates} responseUuid={responseUuid} />;
};

export const Templates = injectStoresV2(
  'templatesStore',
  'biLoggerStore',
  'fedopsLoggerStore',
  'experimentsStore'
)(TemplatesCmp);
