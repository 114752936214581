import { action, computed, makeObservable, observable } from 'mobx';

import { ApiClient } from '../../apiClient/ApiClient';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { TranslationKey } from '../../i18n/i18n';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { Category } from '../../web-api/domain/category';
import { ConfigStore } from './ConfigStore';

export interface CategoriesStoreState {
  bookName: string | null;
  categories: Category[] | null;
}

export interface CategoryGroup {
  key: string;
  shortDisplayNameKey?: TranslationKey;
  displayNameKey: TranslationKey;
  categories: (Category | undefined)[];
}

export class CategoriesStore {
  private apiClient: ApiClient;
  private errorReporter: ErrorReporter;
  private readonly experimentsStore: ExperimentsStore;
  private readonly configStore: ConfigStore;
  public bookName: string | null = null;
  public categories: Category[] | null = null;
  public networkError: Error | null = null;

  constructor(
    {
      apiClient,
      errorReporter,
      configStore,
      experimentsStore,
    }: {
      apiClient: ApiClient;
      errorReporter: ErrorReporter;
      configStore: ConfigStore;
      experimentsStore: ExperimentsStore;
    },
    initialState?: CategoriesStoreState,
  ) {
    makeObservable<CategoriesStore, 'allCategoriesById' | 'allCategoriesBySlug' | 'setNetworkError' | 'setCategories'>(
      this,
      {
        networkError: observable,
        allCategoriesBySlug: computed,
        allCategoriesById: computed,
        sortCategories: computed,
        getCategoryBySlug: action,
        initialFetch: action,
        setNetworkError: action,
        setCategories: action,
      },
    );

    this.apiClient = apiClient;
    this.errorReporter = errorReporter;
    this.configStore = configStore;
    this.experimentsStore = experimentsStore;
    if (initialState) {
      this.setCategories(initialState.bookName, initialState.categories);
    }
  }

  private get allCategoriesBySlug(): Map<string, Category> {
    const categories = this.categories || [];
    const allCategories = [...categories];

    categories
      .filter(({ subCategories }) => subCategories)
      .forEach(({ subCategories }) => allCategories.push(...subCategories));

    return new Map(allCategories.filter((item) => item.type !== 'none').map((item) => [item.name, item]));
  }

  private get allCategoriesById(): Map<string, Category> {
    const categories = this.categories || [];
    const allCategories = [...categories];

    categories
      .filter(({ subCategories }) => subCategories)
      .forEach(({ subCategories }) => allCategories.push(...subCategories));

    return new Map(allCategories.map((item) => [item.categoryId, item]));
  }

  public getParentCategoryBySlug(slug: string): Category | undefined {
    return (this.categories ?? []).find(
      ({ subCategories }) => subCategories && subCategories.some(({ name }) => name === slug),
    );
  }

  public isCategoriesRelated(parentSlug: string, subCategorySlug: string): boolean {
    return this.getParentCategoryBySlug(subCategorySlug)?.name === parentSlug;
  }

  public get sortCategories(): Category[] {
    return Array.from(this.allCategoriesBySlug.values()).filter(({ type }) => type === 'sort');
  }

  public get categoryGroups(): CategoryGroup[] {
    const enabledTemplatesPage2_1 = this.experimentsStore.is('specs.funnel.EnableTemplatesPage2.1', 'true');

    const categoryGroups: CategoryGroup[] =
      this.bookName === 'Buckets Test 2'
        ? [
            {
              key: 'business',
              displayNameKey: 'categoriesMenu.categoryGroup.business',
              categories: ['business', 'industrial-maintenance', 'health', 'landing-pages'].map(this.getCategoryBySlug),
            },
            {
              key: 'store',
              displayNameKey: 'categoriesMenu.categoryGroup.store',
              categories: ['online-store'].map(this.getCategoryBySlug),
            },
            {
              key: 'creative',
              displayNameKey: 'categoriesMenu.categoryGroup.creative',
              categories: ['portfolio', 'art-design', 'photography', 'entertainment', 'music'].map(
                this.getCategoryBySlug,
              ),
            },
            {
              key: 'lifestyle',
              displayNameKey: 'categoriesMenu.categoryGroup.lifestyle',
              categories: [
                'beauty-wellness-b',
                'sports-fitness',
                'food-restaurants',
                'travel',
                'events',
                'fashion-and-style',
              ].map(this.getCategoryBySlug),
            },
            {
              key: 'personal',
              displayNameKey: 'categoriesMenu.categoryGroup.personal',
              categories: ['blog', 'personal'].map(this.getCategoryBySlug),
            },
            {
              key: 'community',
              displayNameKey: 'categoriesMenu.categoryGroup.community',
              categories: ['education', 'communities'].map(this.getCategoryBySlug),
            },
          ]
        : [
            {
              key: 'businessAndServices',
              displayNameKey: 'categoriesMenu.categoryGroup.businessAndServices',
              shortDisplayNameKey: 'categoriesMenu.categoryGroup.business',
              categories: enabledTemplatesPage2_1
                ? [
                    'business',
                    'one-page',
                    'industrial',
                    'health-wellness',
                    'beauty-and-hair',
                    'fashion-and-style',
                    'travel-tourism',
                    'restaurants-food',
                    'professional-cv',
                  ].map(this.getCategoryBySlug)
                : [
                    'business',
                    'health-wellness',
                    'beauty-and-hair',
                    'fashion-and-style',
                    'travel-tourism',
                    'restaurants-food',
                    'one-page',
                    'professional-cv',
                  ].map(this.getCategoryBySlug),
            },
            {
              key: 'store',
              displayNameKey: 'categoriesMenu.categoryGroup.store',
              categories: ['online-store'].map(this.getCategoryBySlug),
            },
            {
              key: 'creative',
              displayNameKey: 'categoriesMenu.categoryGroup.creative',
              categories: ['photography', 'design', 'music', 'creative-arts', 'portfolio-cv', 'video'].map(
                this.getCategoryBySlug,
              ),
            },
            {
              key: 'community',
              displayNameKey: 'categoriesMenu.categoryGroup.community',
              categories: ['education', 'communities', 'events'].map(this.getCategoryBySlug),
            },
            {
              key: 'blog',
              displayNameKey: 'categoriesMenu.categoryGroup.blog',
              categories: ['blog'].map(this.getCategoryBySlug),
            },
          ];

    return categoryGroups
      .filter(Boolean)
      .map(({ categories, ...rest }) => ({
        ...rest,
        categories: categories.filter((category) => this.categories?.includes(category)),
      }))
      .filter(({ categories }) => categories.length > 0);
  }

  public getCategoryBySlug = (slug: string): Category | undefined => this.allCategoriesBySlug.get(slug);

  public getCategoryById = (categoryId: string): Category | undefined => this.allCategoriesById.get(categoryId);

  public async initialFetch(): Promise<void> {
    try {
      const { bookName, items } = await this.apiClient.fetchCategories();
      this.setCategories(bookName, items);
    } catch (error) {
      this.setNetworkError(error);
      this.errorReporter.reportError(error);
      this.setCategories(null, null);
    }
  }

  private setNetworkError(error: Error) {
    this.networkError = error;
  }

  private setCategories(bookName: string | null, categories: Category[] | null) {
    this.bookName = bookName;
    this.categories = categories;
  }

  serialize(): CategoriesStoreState {
    return {
      bookName: this.bookName,
      categories: this.categories,
    };
  }
}
