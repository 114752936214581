import { computed, observable, observe, makeObservable } from 'mobx';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { RouterStore } from '../../stores/reactRouterStore/RouterStore';
import { joinSearchQuery, parse, stringify } from '../../libs/queryString';
import { HomePage, HomePageParams, homeRoute } from './routes/homeRoute';
import { CategoryPage, CategoryPageParams, categoryRoute } from './routes/categoryRoute';
import { SubCategory, SubCategoryPageParams, subCategoryRoute } from './routes/subCategoryRoute';
import { SearchPage, SearchPageParams, searchRoute } from './routes/searchRoute';
import { RouteLocation } from './routes/createRoute';
import { FilterCollection, EMPTY_FILTERS, isLocationsSame } from './routes/sortFiltersParams';

export interface RoutingStoreState {
  primaryCategorySlug: string;
}

interface RoutingStoreParams {
  experimentsStore: ExperimentsStore;
}

interface Matches {
  home: HomePageParams | null;
  category: CategoryPageParams | null;
  subCategory: SubCategoryPageParams | null;
  search: SearchPageParams | null;
}

interface LocationBuilders {
  home: typeof homeRoute.build;
  category: typeof categoryRoute.build;
  subCategory: typeof subCategoryRoute.build;
  search: typeof searchRoute.build;
  current: typeof homeRoute.build;
}

interface BuildParams {
  page?: number;
  sortCategorySlug?: string | null;
  filters?: FilterCollection;
}

type BuildRouteParams = BuildParams &
  ({} | { criteria: string } | { categorySlug: string; subCategorySlug?: string | null });

type GlobalUrlParams = {
  dashboardFirstMetaSiteId?: string;
  siteCreationFolderId?: string;
  petri_ovr?: string;
  hirePartnerBanner?: string;
  ref?: string;
};

export type MatchedRoute = HomePage | CategoryPage | SubCategory | SearchPage;

const getLocationMatches = (location: RouteLocation): Matches => {
  if (!location) {
    return {
      home: null,
      category: null,
      subCategory: null,
      search: null,
    };
  }

  return {
    home: homeRoute.match(location),
    category: categoryRoute.match(location),
    subCategory: subCategoryRoute.match(location),
    search: searchRoute.match(location),
  };
};

export class RoutingStore extends RouterStore {
  private readonly experimentsStore: ExperimentsStore;
  public readonly primaryCategorySlug: string;

  public previousLocation: RouteLocation | null = null;

  constructor({ experimentsStore }: RoutingStoreParams, { primaryCategorySlug }: RoutingStoreState) {
    super();

    makeObservable(this, {
      previousLocation: observable,
      matchedRoute: computed,
      previousMatchedRoute: computed,
      alternateCanonicalLink: computed,
      activeCategorySlug: computed,
      activeSubCategorySlug: computed,
      appliedFilters: computed,
      appliedFiltersCount: computed,
      currentPage: computed,
      nextPage: computed,
      nextPageLocation: computed,
      previousPageLocation: computed,
      templatePrefix: computed,
    });

    this.experimentsStore = experimentsStore;
    this.primaryCategorySlug = primaryCategorySlug;
    observe(this, 'location', (changes) => {
      if (changes.type === 'update') {
        this.previousLocation = changes.oldValue as RouteLocation;
      }
    });
  }

  public getGlobalUrlParams(): GlobalUrlParams {
    const queryParams = parse(this.location?.search || '');
    const { dashboardFirstMetaSiteId, siteCreationFolderId, petri_ovr, hirePartnerBanner } = queryParams;
    return { dashboardFirstMetaSiteId, siteCreationFolderId, petri_ovr, hirePartnerBanner };
  }

  private appendGlobalUrlParams = (income: RouteLocation): RouteLocation => {
    const { pathname, search } = income;
    const searchWithGlobalParams = joinSearchQuery(search, stringify(this.getGlobalUrlParams()));

    return {
      pathname,
      search: searchWithGlobalParams,
    };
  };

  serialize(): RoutingStoreState {
    return {
      primaryCategorySlug: this.primaryCategorySlug,
    };
  }

  private getMatchedRoute = (matches: Matches): MatchedRoute | null => {
    if (matches.category) {
      return {
        routeName: 'category',
        params: matches.category,
      };
    }
    if (matches.subCategory) {
      return {
        routeName: 'subCategory',
        params: matches.subCategory,
      };
    }
    if (matches.search) {
      return {
        routeName: 'search',
        params: matches.search,
      };
    }
    return matches.home
      ? {
        routeName: 'home',
        params: matches.home || {},
      }
      : null;
  };

  public get matchedRoute(): MatchedRoute | null {
    return this.getMatchedRoute(getLocationMatches(this.location));
  }

  public get previousMatchedRoute(): MatchedRoute | null {
    return this.previousLocation ? this.getMatchedRoute(getLocationMatches(this.previousLocation)) : null;
  }

  public rebuildRoute(params: Partial<BuildRouteParams>): RouteLocation {
    return this.buildRoute({
      criteria: '',
      categorySlug: this.primaryCategorySlug,
      subCategorySlug: null,
      ...(this.matchedRoute?.params ?? {}),
      ...params,
    });
  }

  private buildRoute(params: BuildRouteParams): RouteLocation {
    const defaultParams = {
      criteria: '',
      categorySlug: this.primaryCategorySlug,
      subCategorySlug: null,
      page: 1,
      sortCategorySlug: null,
      filters: EMPTY_FILTERS,
      ...params,
    };

    const isHomeRoute =
      defaultParams.categorySlug === this.primaryCategorySlug &&
      defaultParams.page === 1 &&
      defaultParams.sortCategorySlug === null &&
      isFiltersEmpty(defaultParams.filters);

    if (defaultParams.criteria) {
      return this.locationBuilders.search(defaultParams);
    }

    if (defaultParams.subCategorySlug) {
      return this.locationBuilders.subCategory(defaultParams);
    }

    if (isHomeRoute) {
      return this.locationBuilders.home({});
    }

    return this.locationBuilders.category(defaultParams);
  }

  public get locationBuilders(): LocationBuilders & { current: any } {
    return {
      current: () => {
        return this.appendGlobalUrlParams({ pathname: this.location.pathname, search: '' });
      },
      home: () => this.appendGlobalUrlParams(homeRoute.build({})),
      category: (params: CategoryPageParams) => {
        const onlyCategoryLocation = categoryRoute.build({ categorySlug: params.categorySlug });
        const fullCategoryLocation = categoryRoute.build(params);

        const location =
          params.categorySlug === this.primaryCategorySlug &&
            isLocationsSame(onlyCategoryLocation, fullCategoryLocation)
            ? homeRoute.build({})
            : categoryRoute.build(params);

        return this.appendGlobalUrlParams(location);
      },
      subCategory: (params: SubCategoryPageParams) => this.appendGlobalUrlParams(subCategoryRoute.build(params)),
      search: (params: SearchPageParams) => {
        const location = params.criteria === '' ? homeRoute.build({}) : searchRoute.build(params);
        return this.appendGlobalUrlParams(location);
      },
    };
  }

  public get alternateCanonicalLink(): RouteLocation | undefined {
    if (this.matchedRoute?.routeName === 'home') {
      return homeRoute.build({});
    }

    if (this.matchedRoute?.routeName === 'category') {
      const { categorySlug } = this.matchedRoute.params;
      return categoryRoute.build({ categorySlug });
    }

    if (this.matchedRoute?.routeName === 'subCategory') {
      const { categorySlug, subCategorySlug } = this.matchedRoute.params;
      return subCategoryRoute.build({ categorySlug, subCategorySlug });
    }

    return undefined;
  }

  public get activeCategorySlug(): string | undefined {
    if (this.matchedRoute?.routeName === 'home') {
      return this.primaryCategorySlug;
    }

    if (this.matchedRoute?.routeName === 'category' || this.matchedRoute?.routeName === 'subCategory') {
      return this.matchedRoute.params.categorySlug;
    }

    return undefined;
  }

  public get activeSubCategorySlug(): string | undefined {
    if (this.matchedRoute?.routeName === 'subCategory') {
      return this.matchedRoute.params.subCategorySlug;
    }

    return undefined;
  }

  public get activeSearchCriteria(): string | undefined {
    if (this.matchedRoute?.routeName === 'search') {
      return this.matchedRoute.params.criteria;
    }

    return undefined;
  }

  public get activeSortCategorySlug(): string | undefined {
    if (this.matchedRoute?.routeName !== 'home') {
      return this.matchedRoute.params.sortCategorySlug;
    }

    return undefined;
  }

  public get appliedFilters(): FilterCollection | null {
    if (
      this.matchedRoute?.routeName === 'search' ||
      this.matchedRoute?.routeName === 'category' ||
      this.matchedRoute?.routeName === 'subCategory'
    ) {
      return isFiltersEmpty(this.matchedRoute.params.filters) ? null : this.matchedRoute.params.filters;
    }
    return null;
  }

  public get appliedFiltersCount(): number {
    return this.appliedFilters ? filtersCount(this.appliedFilters) : 0;
  }

  get currentPage(): number {
    if (this.matchedRoute?.routeName === 'home') {
      return 1;
    }
    return this.matchedRoute.params.page;
  }

  get nextPage(): number {
    if (this.matchedRoute?.routeName === 'home') {
      return 2;
    }
    return (this.matchedRoute?.params.page ?? 0) + 1;
  }

  get nextPageLocation(): RouteLocation {
    return this.rebuildRoute({ page: this.nextPage });
  }

  get previousPageLocation(): RouteLocation | undefined {
    if (!this.matchedRoute || this.matchedRoute.routeName === 'home' || this.matchedRoute.params.page <= 1) {
      return undefined;
    }

    const { page } = this.matchedRoute.params;
    return this.rebuildRoute({ page: page - 1 });
  }

  get templatePrefix(): string | null {
    if (this.matchedRoute?.routeName === 'search') {
      return this.matchedRoute.params.criteria;
    }
    if (this.matchedRoute?.routeName === 'category') {
      return this.matchedRoute.params.categorySlug;
    }
    if (this.matchedRoute?.routeName === 'subCategory') {
      return this.matchedRoute.params.subCategorySlug;
    }
    return this.matchedRoute?.routeName;
  }
}

export function isFiltersEmpty(filters?: FilterCollection) {
  if (!filters) {
    return true;
  }
  const { features, layouts, colors, colorStyles } = filters;
  return [...features, ...layouts, ...colors, ...colorStyles].length === 0;
}

export function filtersCount(filters?: FilterCollection): number {
  if (!filters) {
    return 0;
  }
  const { features, layouts, colors, colorStyles } = filters;
  return [...features, ...layouts, ...colors, ...colorStyles].length;
}
