import React, { useEffect, useState } from 'react';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { HtmlHead } from '../HtmlHead/HtmlHead';
import { HeaderTitle } from '../HeaderTitle/HeaderTitle';
import { HeaderMenuPanel } from '../HeaderMenuPanel/HeaderMenuPanel';
import { DealerBanner } from '../DealerBanner/DealerBanner';
import { TemplatesPage } from '../TemplatesPage/TemplatesPage';
import { ScrollToTopContext } from '../../contexts/ScrollToTopContext';
import { SeoSection } from '../SeoSection/SeoSection';
import { SiteGeneratorBanner } from '../SiteGeneratorBanner/SiteGeneratorBanner';
import { RecommendedTemplatesSection } from '../RecommendedTemplatesSection';
import { HirePartnerBanner } from '../HirePartnerBanner/HirePartnerBanner';
import { getBiElementData } from './getBiElementData';
import { appDataHooks } from './App.dataHooks';
import '../../styles/global.scss';
import s from './App.scss';

interface AppProps {
  biLoggerStore: BILoggerStore;
  experimentsStore: ExperimentsStore;
}

const AppCmp = (props: AppProps) => {
  const { biLoggerStore, experimentsStore } = props;
  const enableSearchAssistant = experimentsStore.is('specs.funnel.enableSearchAssistant', 'true');

  const scrollToTitleRef = React.useRef<HTMLDivElement>();
  const subTitleRef = React.useRef<HTMLDivElement>();

  const [importedComponent, setImportedComponent] = useState(null);

  useEffect(() => {
    const importComponent = async () => {
      if (!enableSearchAssistant) {
        return;
      }

      const module = await import('../SearchAssistant/SearchAssistant');
      const SearchAssistant = module.SearchAssistant;

      setImportedComponent(<SearchAssistant />);
    };

    importComponent();
  }, []);

  const scrollToTop = () => {
    if (window?.innerWidth > 1024) {
      scrollToTitleRef.current.scrollIntoView();
    } else {
      // Scroll to the top when show mobile Wix Header
      window.scroll(0, 0);
    }
  };
  const focusOnSubTitle = () => subTitleRef.current && subTitleRef.current.focus();

  React.useEffect(() => {
    const handleBiElementClick = (event: MouseEvent): void => {
      biLoggerStore.logClickElement(getBiElementData(event.target as HTMLElement));
    };

    document.body.addEventListener('click', (event) => handleBiElementClick(event));
    return () => document.body.removeEventListener('click', handleBiElementClick);
  });

  return (
    <ScrollToTopContext.Provider value={{ scrollToTop }}>
      <div data-hook="app">
        <HtmlHead />
        <DealerBanner bannerPosition="aef4fb1f-f954-48e4-a31d-b4a49ca84955" />
        <div ref={scrollToTitleRef} />
        <HeaderTitle dataHook={appDataHooks.headerTitle()} focusOnSubTitle={focusOnSubTitle} />
        <HeaderMenuPanel dataHook={appDataHooks.headerMenuPanel()} focusOnSubTitle={focusOnSubTitle} />
        <RecommendedTemplatesSection />
        <div className={s.body}>
          <TemplatesPage dataHook={appDataHooks.templatesPage()} />
        </div>
        {experimentsStore.is('specs.funnel.TemplatesGalleryEnableSGBanner', 'true') ? (
          <SiteGeneratorBanner />
        ) : (
          <DealerBanner bannerPosition="33ff7d65-a3c3-4c88-bfca-e0924bb5cfac" />
        )}
        {importedComponent}
        <HirePartnerBanner />
        <SeoSection dataHook={appDataHooks.seoSection()} />
      </div>
    </ScrollToTopContext.Provider>
  );
};

export const App = injectStoresV2('biLoggerStore', 'experimentsStore')(AppCmp);
