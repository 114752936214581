import React, { useEffect } from 'react';
import cs from 'classnames';
import { useDebounceCallback } from '@react-hook/debounce';
import { searchRoute } from '../../stores/routes/searchRoute';
import { BaseURLStore } from '../../stores/BaseURLStore';
import { HeaderStore } from '../../stores/HeaderStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { SearchWithSuggestionsStore } from '../../stores/SearchWithSuggestionsStore';
import { useScrollToTop } from '../../contexts/ScrollToTopContext';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { FedopsLoggerStore } from '../../stores/FedopsLoggerStore';
import SearchBlue from '../../../Icons/SearchBlue.svg';
import { searchDataHooks } from './Search.dataHooks';
import { SuggestionsDropdown } from './SuggestionsDropdown/SuggestionsDropdown';
import { SearchInput } from './SearchInput/SearchInput';
import { SearchClearButton } from './SearchClearButton/SearchClearButton';
import { SearchLabel } from './SearchLabel/SearchLabel';

import styles from './Search.scss';

export const SUGGESTIONS_FETCH_TIMEOUT = 500;

interface SearchWithSuggestionProps {
  headerStore: HeaderStore;
  baseURLStore: BaseURLStore;
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  templatesStore: TemplatesStore;
  biLoggerStore: BILoggerStore;
  routingStore: RoutingStore;
  fedopsLoggerStore: FedopsLoggerStore;
  dataHook?: string;
  focusOnSubTitle?: () => void;
}

export const SearchCmp: React.FC<SearchWithSuggestionProps> = (props) => {
  const {
    baseURLStore,
    dataHook,
    headerStore,
    focusOnSubTitle,
    searchWithSuggestionsStore,
    templatesStore,
    biLoggerStore,
    fedopsLoggerStore,
    routingStore,
  } = props;
  const searchInputRef = React.createRef<HTMLInputElement>();
  const searchFormRef = React.createRef<HTMLFormElement>();
  const { scrollToTop } = useScrollToTop();
  const { isSticky, searchCriteria, isSearchInputFocused, isLargeSearchInput, setIsRecomendedSctionOpened } =
    headerStore;

  const search = (focusOnTemplates?: boolean) => {
    const criteria = searchWithSuggestionsStore.preSelectedSuggestion?.text ?? searchCriteria.trim();

    if (criteria) {
      if (searchWithSuggestionsStore.preSelectedSuggestion) {
        headerStore.setSearchCriteria(searchWithSuggestionsStore.preSelectedSuggestion.text);
      }

      const suggestion =
        searchWithSuggestionsStore.preSelectedSuggestion ?? searchWithSuggestionsStore.getSuggestionByText(criteria);
      const suggestedCategoryLocation = searchWithSuggestionsStore.getSuggestedCategoryLocation(
        criteria,
        suggestion?.categoryId
      );

      searchWithSuggestionsStore.setSelectedSuggestion(suggestion);
      if (suggestion) {
        biLoggerStore.logSearchSuggestionSelectAdiBiEvent({
          suggestion,
          searchType: searchWithSuggestionsStore.selectedSuggestion.searchType,
          criteria,
        });
      }

      if (suggestedCategoryLocation) {
        templatesStore.setRedirectedCriteria(criteria);
        routingStore.history.push(suggestedCategoryLocation);
      } else {
        fedopsLoggerStore.startSearchInteraction();
        routingStore.history.push(routingStore.locationBuilders.search({ criteria }));
      }

      closeSuggestionsDropdown();
      setSearchFormFocusOut();

      scrollToTop && scrollToTop();
      if (focusOnTemplates) {
        focusOnSubTitle && focusOnSubTitle();
      }
      return;
    }

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const setSearchFormFocusOut = () => {
    headerStore.setSearchInputFocused(false);
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const closeSuggestionsDropdown = () => {
    searchWithSuggestionsStore.setIsSearchSuggestionDropdownOpened(false);
    searchWithSuggestionsStore.setHighlightedSuggestionIndex(null);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    headerStore.setSearchCriteria('');
    closeSuggestionsDropdown();
  };

  const fetchSuggestions = () => {
    const trimmedCriteria = searchCriteria.trim();
    if (isSearchInputFocused && trimmedCriteria) {
      void searchWithSuggestionsStore.fetchSearchSuggestions(trimmedCriteria);
    }
  };

  const debouncedFetchSearchSuggestions = useDebounceCallback(fetchSuggestions, SUGGESTIONS_FETCH_TIMEOUT);

  const handleFormSubmit = (event: React.KeyboardEvent<HTMLFormElement>) => {
    event.preventDefault();
    search(true);
  };

  const handleFormKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Escape') {
      closeSuggestionsDropdown();
    }
    setIsRecomendedSctionOpened(false);
  };

  const handleFormFocusOut = ({ relatedTarget }: React.FocusEvent<HTMLFormElement>) => {
    const isClickOutsideFormElements = relatedTarget === null || !searchFormRef.current.contains(relatedTarget as Node);
    if (isClickOutsideFormElements) {
      closeSuggestionsDropdown();
      headerStore.setSearchInputFocused(false);
    }
  };

  useEffect(() => {
    searchWithSuggestionsStore.setIsSearchSuggestionDropdownOpened(false);

    const searchInput = searchInputRef.current;
    if (searchInput && isSearchInputFocused) {
      searchInput.focus();
    }
  });

  useEffect(() => {
    debouncedFetchSearchSuggestions();
  }, [searchCriteria, isSearchInputFocused, debouncedFetchSearchSuggestions]);

  return (
    <form
      role="search"
      data-hook={dataHook}
      className={cs(styles.search, {
        [styles.largeSearch]: isLargeSearchInput,
        [styles.sticky]: isSticky,
        [styles.focused]: isSearchInputFocused,
      })}
      method="GET"
      action={baseURLStore.buildURL(searchRoute.build({ criteria: '' }))}
      onSubmit={handleFormSubmit}
      onKeyDown={handleFormKeyDown}
      onBlur={handleFormFocusOut}
      onClick={() => {
        headerStore.setSearchInputFocused(true);
      }}
      ref={searchFormRef}
    >
      {isLargeSearchInput && <SearchBlue className={styles.searchIcon} />}
      <SearchInput dataHook={searchDataHooks.input()} forwardedRef={searchInputRef} />
      <SuggestionsDropdown dataHook={searchDataHooks.dropdown()} onSearch={search} />
      <SearchClearButton onClick={handleClear} dataHook={searchDataHooks.closeButton()} />
      {!isLargeSearchInput && <SearchLabel dataHook={searchDataHooks.label()} />}
      <input type="hidden" name="page" value="1" />
    </form>
  );
};

export const Search = injectStoresV2(
  'baseURLStore',
  'headerStore',
  'searchWithSuggestionsStore',
  'templatesStore',
  'biLoggerStore',
  'routingStore',
  'fedopsLoggerStore'
)(SearchCmp);
