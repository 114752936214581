import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { HttpClient } from '@wix/http-client';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { HeaderStore } from '../../stores/HeaderStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { AI_DATA_LOADING, IRecommendedTemplate, RecommendedTemplate } from './RecommendedTemplate';

import s from './RecommendedTemplatesSection.scss';

type RecommendedTemplateLlmResponse = Record<string, { description: string; badge: string }>;

interface RecommendedTemplatesSectionProps extends WithTranslations {
  templatesStore: TemplatesStore;
  biLoggerStore: BILoggerStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  headerStore: HeaderStore;
}

export const RecommendedTemplatesSectionCmp: FC<RecommendedTemplatesSectionProps> = ({
  t,
  templatesStore,
  experimentsStore,
  headerStore,
}) => {
  const [height, setHeight] = useState('0');
  const [recommendedTemplates, setRecommendedTemplates] = useState<IRecommendedTemplate[] | null>(null);
  const contentRef = useRef(null);
  const { items, bestMatchItemIds } = templatesStore;
  const bestMatchTemplates = items.filter((i) => bestMatchItemIds.includes(i.id));
  const { isRecomendedSctionOpened, setIsRecomendedSctionOpened } = headerStore;

  const isShow =
    bestMatchItemIds.length === 3 && experimentsStore.is('specs.funnel.RecommendedTemplatesSection', 'true');

  useEffect(() => {
    if (!isShow) {
      setIsRecomendedSctionOpened(false);
      return;
    }

    const getDataFromApi = async () => {
      try {
        const loadingTemplates: IRecommendedTemplate[] = bestMatchTemplates.map((t) => ({
          ...t,
          aiDescription: AI_DATA_LOADING,
          aiBadge: AI_DATA_LOADING,
        }));
        setRecommendedTemplates(loadingTemplates);
        setIsRecomendedSctionOpened(true);

        const baseURL = window.__INITIAL_STORES_STATE__.config.baseURL;
        const httpClient = new HttpClient({ baseURL });

        const { data } = await httpClient.post<RecommendedTemplateLlmResponse>('/api/ai/recommended-templates', {
          templates: bestMatchTemplates,
        });

        const templates: IRecommendedTemplate[] = bestMatchTemplates.map((t, i) => ({
          ...t,
          aiDescription: data[`template${i + 1}`].description,
          aiBadge: data[`template${i + 1}`].badge,
        }));

        setRecommendedTemplates(templates);
      } catch (err) {
        const fallback: IRecommendedTemplate[] = bestMatchTemplates.map((template) => ({
          ...template,
          aiDescription: '',
          aiBadge: t('sort.bestMatch.title'),
        }));
        setRecommendedTemplates(fallback);
      }
    };

    getDataFromApi();
  }, []);

  useEffect(() => {
    const handleTransition = async () => {
      if (!isShow) {
        return;
      }

      if (!isRecomendedSctionOpened) {
        setHeight(() => `${contentRef.current.scrollHeight}px`);
        await animationDelay();
        setHeight(() => `0`);
      } else {
        await animationDelay();
        setHeight(() => `${contentRef.current.scrollHeight}px`);
      }
    };

    handleTransition();
  }, [isRecomendedSctionOpened]);

  return (
    <div
      ref={contentRef}
      className={classNames(s.colapseBox, { [s.colapsed]: false })}
      style={{ height }}
      onTransitionEnd={() => {
        if (isRecomendedSctionOpened) {
          setHeight('auto');
        }
      }}
    >
      {recommendedTemplates && (
        <div className={s.recommendedTemplatesSection}>
          <h3 className={s.title}>{t('recommendedTemplates.section.title')}</h3>

          <div className={s.templatesBox}>
            {recommendedTemplates.slice(0, 3).map((template, i) => (
              <RecommendedTemplate template={template} recommendedTemplateIndex={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const RecommendedTemplatesSection = withTranslations(
  injectStoresV2(
    'templatesStore',
    'biLoggerStore',
    'configStore',
    'experimentsStore',
    'headerStore',
  )(RecommendedTemplatesSectionCmp),
);

const animationDelay = () => new Promise((r) => setTimeout(() => r(true), 100));
